<template>
  <div class="mx-auto h-screen max-w-screen-lg md:p-5">

    <div ref="hovercard" class="hovercard m-auto mt-10 flex flex-col w-1/2 rounded overflow-hidden shadow border border-gray-300 bg-white dark:(bg-gray-600 border-gray-800)">
      <div class="relative m-2 md:m-4 rounded overflow-hidden">
        <img src="@/assets/img/zhou.jpg">
        <div class="absolute top-0 left-0 w-full h-full overlay"></div>
      </div>
      <p class="mb-2 md:mb-4 px-5 md:px-7 font-semibold font-playful text-lg md:text-2xl text-gray-600 dark:(text-gray-200) italic ml-auto">
        Für Zhou
      </p>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'About',
  components: {},
  mounted: function() {
    this.$refs['hovercard'].onmousemove = this.$refs['hovercard'].ontouchmove = (e) => {
      e.stopPropagation();
      e.cancelBubble = true;
      e.preventDefault();
      const rect = e.target.getBoundingClientRect();
      const x = (e.clientX ?? e.touches[0].clientX) - rect.left;
      const y = (e.clientY ?? e.touches[0].clientY) - rect.top;
      const w = rect.width;
      const h = rect.height;

      const mag = 20;
      let rx = -((x / w) * 2 - 1);
      let ry = (y / h) * 2 - 1;
      rx = Math.max(Math.min(rx, 1), -1);
      ry = Math.max(Math.min(ry, 1), -1);

      this.$refs['hovercard'].style.transform = `perspective(1000px) rotateY(${rx * mag}deg) rotateX(${ry * mag}deg)`;

      this.$refs['hovercard'].querySelector('.overlay').style.background = `linear-gradient(${(0.03 + rx * 0.05)}turn, rgba(255, 255, 255, 0.07), rgba(255, 255, 255, 0.2) ${(ry * 0.4 + 0.5) * 100}%, transparent)`;
    };

    this.$refs['hovercard'].onmouseleave = this.$refs['hovercard'].ontouchend = (e) => {
      this.$refs['hovercard'].style.transform = `perspective(1000px) rotateY(0deg) rotateX(0deg)`;
    };
  },
  methods: {
    onBackToStart: function() {
			this.$router.replace({name: 'Home'});
		},
  },
}
</script>

<style>

.hovercard {
  will-change: transform;
  transition: transform 0.1s linear;
}
.hovercard::after {
  content: ' ';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
}
.hovercard .overlay {
  transform: translateZ(1);
  background: linear-gradient(0.03turn, rgba(255, 255, 255, 0.07), rgba(255, 255, 255, 0.2) 50%, transparent);
}

</style>
